import {Link} from 'react-router-dom';
import {CustomMenu} from '@common/menus/custom-menu';
import {useSettings} from '@ui/settings/use-settings';
import {Trans} from '@ui/i18n/trans';

export function AuthLayoutFooter() {
  const {branding} = useSettings();
  const year = new Date().getFullYear();
  return (
    <div className="mt-auto flex items-center gap-30 pb-32 pt-42 text-sm text-muted">
      <Link className="transition-colors hover:text-fg-base" to="/">
      <Trans
          message="Copyright © :year :name, All Rights Reserved"
          values={{year, name: branding.site_name}}
        />
      </Link>
      <CustomMenu
        menu="auth-page-footer"
        orientation="horizontal"
        itemClassName="hover:text-fg-base transition-colors"
      />
    </div>
  );
}
